<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="姓名">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="成交日期">
            <el-date-picker
              v-model="search.cj_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
			<el-date-picker
			  v-model="search.cj_endtime"
			  type="date"
			  class="w130"
			  placeholder="结束日期"
			  value-format="yyyy-MM-dd"
			></el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          v-if="!userInfo.shop_id"
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表','')"
        >导出数据</el-button>
        <!-- <el-button type="primary" class="mb10" size="mini" @click="tapBtn('添加客户','')">添加客户</el-button> -->
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="录入日期" width="150" prop="create_time" />
            <ElTableColumn label="姓名" prop="customer_name" />
            <ElTableColumn label="市场" prop="market_name" />
            <ElTableColumn label="店面" prop="shop_name" />
            <ElTableColumn label="负责人" prop="nurse_name" />
            <ElTableColumn label="成交金额" prop="cj_money" />
            <ElTableColumn label="修复进展" prop="progress_name" />
            <ElTableColumn label="会员等级" prop="grade_name" />
            <ElTableColumn label="疤痕类型" width="170" prop="scar_aggregate" />
            <ElTableColumn label="操作" fixed="right" width="100">
              <template slot-scope="{ row }">
                <span class="theme" size="mini" @click="tapBtn('添加回访',row)">添加回访</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
    <!-- 回访添加 -->
    <el-dialog title="添加回访记录" :visible.sync="visitClient" :close-on-click-modal="false" width="50%">
      <el-form
        ref="detail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="detail"
        size="mini"
        class="visit"
      >
        <el-form-item label="客户姓名">
          <el-input v-model="detail.customer_name" class="w300" readonly />
        </el-form-item>
        <el-form-item label="店铺">
          <el-input v-model="detail.shop_name" class="w300" readonly />
        </el-form-item>
        <el-form-item label="问题分类" prop="setting_id">
          <el-select v-model="detail.setting_id" placeholder="请输入问题分类" class="w300">
            <el-option
              v-for="item in options.setting_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="护理师">
          <el-input v-model="detail.nurse_name" class="w300" readonly />
        </el-form-item>
        <div class="center">
          <el-form-item label="客户评分" prop="total">
            <el-input v-model="detail.total" class="w130" size="mini" />分
          </el-form-item>
        </div>
        <el-form-item label="回访结果">
          <el-radio-group v-model="detail.result">
            <el-radio :label="1">满意</el-radio>
            <el-radio :label="2">一般</el-radio>
            <el-radio :label="3">不满</el-radio>
            <el-radio :label="4">投诉</el-radio>
            <el-radio :label="5">流失</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="detail.result == 4" label="投诉类型" prop="result_id">
          <el-select v-model="detail.result_id" placeholder="请选择投诉类型" class="w200">
            <el-option
              v-for="item in options.result_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="效果问题" prop="effect_problem_id">
          <el-select
            v-model="detail.effect_problem_id"
            placeholder="请输入效果问题"
            class="w300"
            clearable
          >
            <el-option
              v-for="item in options.effect_problem_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="服务问题" prop="service_issues_id">
          <el-select
            v-model="detail.service_issues_id"
            placeholder="请输入服务问题"
            class="w300"
            clearable
          >
            <el-option
              v-for="item in options.service_issues_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="center" style="display: flex;justify-content: space-between;">
          <el-form-item label="预警/追责">
            <el-radio-group v-model="detail.early_warning">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">预警</el-radio>
              <el-radio :label="2">追责</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="center" style="display: flex;justify-content: space-between;">
          <el-form-item v-if="detail.early_warning == 1" label="预警内容" prop="early_warning_content">
            <el-input
              v-model="detail.early_warning_content"
              class="w300"
              placeholder="请填写预警内容"
              type="textarea"
            />
          </el-form-item>
          <el-form-item v-if="detail.early_warning == 2" label="追责内容" prop="accountability_content">
            <el-input
              v-model="detail.accountability_content"
              class="w300"
              placeholder="请填写追责内容"
              type="textarea"
            />
          </el-form-item>
        </div>
        <el-form-item label="回访内容" prop="return_visit_content">
          <el-input
            v-model="detail.return_visit_content"
            class="w300"
            placeholder="请填写回访内容"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="回访备注">
          <el-input v-model="detail.remarks" class="w300" placeholder="请填写备注" type="textarea" />
        </el-form-item>

        <div class="footerBtn">
          <el-button type="primary" size="mini" @click="submitForm('detail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { addReturnVistT } from "@/api/againVisit";
import {
  newOrderlist,
  clientVisitlist,
  ssettingList,
  getGradeList,
  clientExport
} from "@/api/client";
import { getShopList } from "@/api/shop";

class Search {
  customer_name = "";
  telephone = "";
  shop_id = "";
  cj_time = "";
  cj_endtime="";
}
class Options {
  sex = [
    { id: 0, name: "全部" },
    { id: 1, name: "男" },
    { id: 2, name: "女" }
  ];
  source_id = [];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  membership_leve = [];
  shop_id = [];
  member_classification = [];
  repair_progress = [];
  scar_id = [];
  consumption = [
    { id: 1, name: "大于等于" },
    { id: 2, name: "小于等于" },
    { id: 3, name: "等于" }
  ];
  setting_id = [];
  result_id = [];
  effect_problem_id = [];
  service_issues_id = [];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Detail {
  customer_id = "";
  shop_id = "";
  shop_name = "";
  nurse_id = "";
  result_id = "";
  result = 1;
  service_attitude = 0;
  operation_process = 0;
  effect = 0;
  hygiene = 0;
  total = "";
  early_warning = 0;
  early_warning_content = "";
  accountability_content = "";
  accountability = 0;
  setting_id = "";
  return_visit_content = "";
  remarks = "";
}
class Rules {
  setting_id = [
    { required: false, message: "请选择问题分类", trigger: "change" }
  ];
  nurse_id = [{ required: true, message: "请选择护理师", trigger: "change" }];
  result_id = [
    { required: true, message: "请选择投诉类型", trigger: "change" }
  ];

  total = [
    {
      pattern: /^(?:[1-9]?\d|100)$/,
      message: "请输入0~100数字",
      trigger: "change"
    },
    { required: false, message: "请输入客户评分", trigger: "blur" }
  ];

  effect_problem_id = [
    { required: false, message: "请选择效果问题", trigger: "blur" }
  ];
  service_issues_id = [
    { required: false, message: "请选择服务问题", trigger: "blur" }
  ];
  early_warning_content = [
    { required: true, message: "请选择回预警内容", trigger: "blur" }
  ];
  accountability_content = [
    { required: true, message: "请选择回追责内容", trigger: "blur" }
  ];
  return_visit_content = [
    { required: true, message: "请选择回访内容", trigger: "blur" }
  ];
}
export default {
  name: "ClientNew",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      detail: new Detail(),
      rules: new Rules(),
      list: [],
      idList: "",
      visitClient: false
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_id.push(item);
          } else if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            that.options.repair_progress.push(item);
          } else if (item.category_id == 8) {
            that.options.member_classification.push(item);
          } else if (item.category_id == 9) {
            that.options.setting_id.push(item);
          } else if (item.category_id == 11) {
            that.options.result_id.push(item);
          } else if (item.category_id == 12) {
            that.options.effect_problem_id.push(item);
          } else if (item.category_id == 13) {
            that.options.service_issues_id.push(item);
          }
        });
      });
      getGradeList().then(res => {
        this.options.membership_leve = res.list;
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      clientVisitlist({ ...this.search, ...this.page, is_join_shop: 0 }).then(
        res => {
          this.list = res.list;
          this.page.total = res.dataCount;
        }
      );
    },
    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 点击按钮
    tapBtn(routerName, row) {
      if (routerName == "添加回访") {
        this.visitClient = true;
        this.detail.customer_id = row.id;
        this.detail.customer_name = row.customer_name;
        this.detail.shop_id = row.shop_id;
        this.detail.shop_name = row.shop_name;
        this.detail.nurse_id = row.nurse_id;
        this.detail.nurse_name = row.nurse_name;
      } else {
        const query = {
          id: row.id,
          activeName: "first",
          customer_name: row.customer_name
        };
        this.$router.push({ name: routerName, query });
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加该条回访记录", "add");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "add") {
            this.confirm();
          } else {
            this.getExport();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      clientExport({ id: this.idList, type: 6 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 确认提交
    confirm() {
      addReturnVistT({ ...this.detail }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.visitClient = false;
        this.getList();
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.visit {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    margin: 0px 20px 30px 0;
  }
}
.center {
  width: 100% !important;
}
.footerBtn {
  width: 100%;
  text-align: right;
}
</style>
